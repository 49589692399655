import { graphql } from 'gatsby';
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import PostContent from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

import {
  Map,
  TileLayer,
  GeoJSON
} from 'react-leaflet'
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

import geojson from '../content/geojson/tomofiles.json'

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const PostFullMap = css`
  margin: 0 0 20px;
  height: 400px;

  @media (max-width: 1170px) {
    height: 350px;
  }

  @media (max-width: 800px) {
    height: 300px;
  }
  @media (max-width: 500px) {
    height: 200px;
  }
`;

const MapDistStyle = styled.div`
  text-align: center;
  font-style: oblique;
`

function nameStyle() {
  return {
    color: '#2bb3c0',
    weight: 2.0,
    fillOpacity: 0.45
  };
}

interface PageTemplateProps {
  pathContext: {
    slug: string;
  };
  data: {
    markdownRemark: {
      html: string;
      htmlAst: any;
      excerpt: string;
      timeToRead: string;
      frontmatter: {
        title: string;
        date: string;
        userDate: string;
        image: {
          childImageSharp: {
            fluid: any;
          };
        };
        tags: string[];
        author: {
          id: string;
          bio: string;
          avatar: {
            children: Array<{
              fixed: {
                src: string;
              };
            }>;
          };
        };
      };
    };
  };
}

const AboutTemplate: React.FC<PageTemplateProps> = props => {
  const about = props.data.markdownRemark;

  const [zoom, setZoom] = React.useState(0);

  const contentRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useEffect(() => {
    adaptResize();
  }, [])

  const adaptResize = React.useCallback(() => {
    if (contentRef.current) {
      const elmRect = contentRef.current.getBoundingClientRect();

      if (elmRect.width > 800) {
        setZoom(15);
      } else if (elmRect.width > 500) {
        setZoom(14);
      } else {
        setZoom(13);
      }
    }
  }, []);

  return (
    <IndexLayout>
      <Helmet>
        <title>{about.frontmatter.title}</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <div ref={contentRef}>
              <PostFullHeader>
                <PostFullTitle>{about.frontmatter.title}</PostFullTitle>
              </PostFullHeader>
              {(typeof window) ? 
                <Map addInitHook={["addHandler", "gestureHandling", GestureHandling]}
                  center={[35.71104956979952, 139.75699424743655]} zoom={zoom} css={PostFullMap}
                  zoomControl={false} gestureHandling={true}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <GeoJSON data={geojson} style={nameStyle} />
                </Map>
              : <div />}
              <MapDistStyle>
                <span>GeoJSON形式でPolygon化した名前をLeafletに読み込ませたもの</span>
              </MapDistStyle>
              <PostContent htmlAst={about.htmlAst} />
            </div>
          </article>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default AboutTemplate;

export const query = graphql`
  query {
    markdownRemark(fields: { layout: {eq: "about" } }) {
      html
      htmlAst
      excerpt(truncate: true)
      timeToRead
      frontmatter {
        title
        userDate: date(formatString: "YYYY-MM-DD")
        date
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 3720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author {
          id
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
